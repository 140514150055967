import * as React from "react";
import { graphql, Link } from "gatsby";
import Cta from "../components/CTA/CTAMain";
import Seo from "../components/seo";
import Layout from "../components/Defaults/Layout";
import { useSiteMetadata } from "../hooks/defaults/Use-SiteMetadata";

var slugify = require("slugify");

function titleCase(str) {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
}

const faqPages = ({ data }) => {
	return (
		<Layout>
			<Cta background={true} />

			<div className="mt-4 mx-auto  max-w-screen-xl items-center mb-10 justify-between px-4">
				<h1 className="text-4xl text-blue-500 text-center my-20">
					{/* {titleCase(Search)} */}
				</h1>
				<div className="bg-slate-800 via-blue-300 to-white shadow-xl p-10 rounded outline outline-[4px] hover:outline-[5px] outline-blue-500/20 hover:outline-blue-500/80 outline-offset-[6px] hover:outline-offset-0 duration-300">
					<h3>
						<span className="font-bold mb-2 text-white">Table of Content</span>
					</h3>
					{data?.allFaqsCsv?.group?.map((edge, indx) => {
						return (
							<ul className="flex flex-col">
								<a
									className="
                   text-white text-base hover:text-blue-400"
									href={`#${edge.Related_Service}`}
								>
									{indx + 1} - {edge.Related_Service}
								</a>
							</ul>
						);
					})}
				</div>
				<div className="mt-6 mx-auto  ">
					{data?.allFaqsCsv?.group?.map((edge, indx) => {
						return (
							<>
								{" "}
								<div
									id={edge?.Related_Service}
									href={`#${edge?.Related_Service}`}
									className=" my-10"
								>
									<h3 className="text-blue-400 mb-6 text-2xl ">
										{edge?.Related_Service}
									</h3>
									<div className="grid grid-cols-1 md:grid-cols-4 gap-6 divide divide-y">
										{edge?.relatedQA?.map((item, index) => {
											return (
												<Link
													to={`/faq/${slugify(item, {
														lower: true,
													})}`}
													className={
														"col-span-1   md:col-span-2 shadow  rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300"
													}
												>
													<p className=" text-gray-500  tracking-wide ">
														{titleCase(item)}
													</p>
												</Link>
											);
										})}
									</div>
								</div>
							</>
						);
					})}
				</div>
			</div>

			{/* <RelatedQA RelatedService={RelatedService} data={data?.relatedQA} /> */}
		</Layout>
	);
};
export const Head = ({ pageContext: { Search } }) => {
	const { mainService } = useSiteMetadata();
	return <Seo title={`Frequently Asked ${mainService} Questions`} />;
};

export const query = graphql`
	query {
		allFaqsCsv {
			group(field: { Related_Service: SELECT }) {
				relatedQA: distinct(field: { Search: SELECT })
				Related_Service: fieldValue
			}
		}
	}
`;

export default faqPages;
